<template>
  <div class="integration-step">
    <div class="step-description step-3">
      <div class="sub-step">
        <div class="sub-step-title">
          Step 4:
        </div>
        <ol>
          <li>Go to <b>"Reports"</b> section. You will be redirect to <b>“Payments and Financial Reports”</b> subsection</li>
          <li>
            Copy the <b>Vendor number</b> and paste it to the fields below
            <div class="step-3-input-wrap">
              <div>Vendor #</div>
              <input v-model="vendor_id" class="vendor-id" type="text" placeholder="XXXXXXXX" />
            </div>
          </li>
        </ol>
      </div>
    </div>
    <div class="img-wrap">
      <img src="@/assets/images/integrations/app-store/api-4.png" width="540">
    </div>
  </div>
</template>
<script>
export default {
  name: 'AppStoreConnectViaAPIStep3',
  props: {
    data: {
      type: Object,
      default: {},
    }
  },
  data() {
    return {
      vendor_id: null,
    };
  },
  created() {
    Object.keys(this.data).forEach((key) => {
      this[key] = this.data[key];
    });
  },
  watch: {
    vendor_id() {
      this.$emit('confirmation-changed', this.vendor_id ? {
        vendor_id: this.vendor_id
      } : false);
    },
  },
}
</script>
<style lang="scss" scoped>
.step-3-input-wrap {
  display: flex;
  gap: 16px;
  font-size: 14px;
  align-items: center;
  margin-top: 12px;
  margin-bottom: 12px;

  input {
    height: 44px;
    padding: 10px 16px;
    border: 1px solid var(--neutral-500);
    border-radius: 4px;
    font-size: 16px;

    &.issuer-id {
      width: 220px;
    }

    &.key-id {
      width: 130px;
    }
  }
}
</style>
