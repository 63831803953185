<template>
  <div class="integration-success-step">
    <div class="title">
      Console integration was successful
    </div>
    <div class="success-icon">
      <svg-icon icon="success" />
    </div>
    <div class="last-description">
      For any inquiries or assistance, please feel free to <span class="contact">contact our support team</span>. We appreciate your patience during this integration period.
    </div>
    <base-button height="40px"
                 width="160px"
                 @click="goBack"
                 font-size="16px"
                 border-radius="4px">
      To Apps list
    </base-button>
  </div>
</template>
<script>
import ProgressIndicator from "@/components/UI/ProgressIndicator/index.vue";
import SvgIcon from "@/components/UI/SvgIcon/index.vue";

export default {
  name: 'IntegrationSuccessStep',
  components: {SvgIcon, ProgressIndicator},
  methods: {
    goBack() {
      this.$router.push({name: 'IntegrationSettingsList'});
    },
  },
}
</script>
<style lang="scss" scoped>
.integration-success-step {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 50px 0 70px;
  line-height: 24px;

  div {
    max-width: 580px;
    text-align: center;
  }

  .title {
    font-size: 20px;
    font-weight: 600;
    color: var(--neutral-900);
    margin-bottom: 30px;
  }

  .last-description {
    color: var(--neutral-700);
    margin-bottom: 24px;

    .contact {
      color: var(--primary-default);
      cursor: pointer;
    }
  }

  .success-icon {
    margin-bottom: 30px;

    .svg-icons {
      font-size: 30px;
    }
  }

  button {
    font-weight: 600;
  }
}
</style>
