<template>
  <div class="integration-settings">
    <div class="common-white-container settings-container">
      <div class="back-link">
        <router-link :to="{name: 'IntegrationSettingsList'}">
          <svg-icon icon="arrow-left" />
          Back to App list
        </router-link>

        <base-button v-if="!isFinished"
                     height="36px"
                     width="95px"
                     @click="cancel"
                     mode="outline"
                     font-size="14px"
                     border-radius="4px">
          <span>Cancel</span>
        </base-button>
      </div>

      <div class="page-title">
        <div>
          App Store Console Connecting via API
        </div>
        <div class="steps-counter" v-if="!isFinished">
          {{ currentStep }}/{{ stepsAmount }}
        </div>
      </div>

      <integration-success-step v-if="isFinished" />
      <progress-indicator v-else-if="editMode && !isDataLoaded">
        Loading...
      </progress-indicator>
      <div v-else class="integration-step-wrap">
        <component :is="currentStepComponent"
                   :data="data"
                   :error="error"
                   @confirmationChanged="confirmationChanged" />

        <div class="buttons">
          <base-button v-if="currentStep > 1"
                       height="40px"
                       width="160px"
                       border-radius="4px"
                       @click="currentStep--"
                       mode="outline">
            <span>Back</span>
          </base-button>

          <base-button v-if="currentStep < stepsAmount"
                       :disabled="!isNextStepAvailable"
                       height="40px"
                       width="160px"
                       @click="currentStep++"
                       border-radius="4px">
            <span>Next</span>
          </base-button>

          <base-button v-if="currentStep === stepsAmount"
                       :disabled="!isIntegrationEnabled"
                       height="40px"
                       width="200px"
                       @click="submit"
                       border-radius="4px">
            <span>Add Integration</span>
          </base-button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import AppStoreConnectViaAPIStep1 from '@/views/Account/IntegrationSettings/AppStore/ViaAPI/Steps/AppStoreConnectViaAPIStep1.vue';
import AppStoreConnectViaAPIStep2 from '@/views/Account/IntegrationSettings/AppStore/ViaAPI/Steps/AppStoreConnectViaAPIStep2.vue';
import AppStoreConnectViaAPIStep3 from '@/views/Account/IntegrationSettings/AppStore/ViaAPI/Steps/AppStoreConnectViaAPIStep3.vue';
import AppStoreConnectViaAPIStep4 from '@/views/Account/IntegrationSettings/AppStore/ViaAPI/Steps/AppStoreConnectViaAPIStep4.vue';
import SvgIcon from "@/components/UI/SvgIcon/index.vue";
import {httpRequest} from "@/api";
import ProgressIndicator from "@/components/UI/ProgressIndicator/index.vue";
import IntegrationSuccessStep from "@/views/Account/IntegrationSettings/AppStore/ViaAPI/Steps/Success.vue";

export default {
  name: 'AppStoreConnectViaAPI',
  components: {
    IntegrationSuccessStep,
    ProgressIndicator,
    SvgIcon,
    'step-1': AppStoreConnectViaAPIStep1,
    'step-2': AppStoreConnectViaAPIStep2,
    'step-3': AppStoreConnectViaAPIStep3,
    'step-4': AppStoreConnectViaAPIStep4,
  },
  props: {
    editMode: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      currentStep: 1,
      stepsAmount: 4,
      stepsConfirmation: {},
      data: {},
      isDataLoaded: false,
      isFinished: false,
      isInProcess: false,
      error: false,
    };
  },
  mounted() {
    if (this.editMode) {
      this.loadData();
    }
  },
  methods: {
    async loadData() {
      const result = await httpRequest(
        'GET',
        process.env.VUE_APP_API_URL + this.$ApiUrls.integrations.APP_STORE_CONNECT_GET_KEY
          + '?app_id=' + this.$route.params.app_id,
      );
      if (result.key) {
        this.data = result.key;
        this.currentStep = 3;
        this.confirmAllSteps();
      }
      this.isDataLoaded = true;
    },
    async submit() {
      this.isInProcess = true;
      this.error = false;

      let formData = new FormData();
      Object.keys(this.data).forEach((key) => {
        formData.append(key, this.data[key]);
      });
      formData.append('app_id', this.$route.params.app_id);

      const result = await httpRequest(
        'POST',
        process.env.VUE_APP_API_URL + this.$ApiUrls.integrations.APP_STORE_CONNECT_SAVE_KEY,
        formData
      );
      if (result.result) {
        this.isFinished = true;
      } else {
        this.currentStep = 3;
        this.error = true;
      }
      this.isInProcess = false;
    },
    cancel() {
      this.$router.push({name: 'IntegrationSettingsList'});
    },
    confirmAllSteps() {
      for (let i = 1; i <= this.stepsAmount; i++) {
        this.stepsConfirmation[i] = true;
      }
    },
    confirmationChanged(value) {
      this.error = false;
      if (value) {
        this.stepsConfirmation[this.currentStep] = true;
        if (typeof value === 'object') {
          Object.keys(value).forEach((key) => {
            this.data[key] = value[key];
          });
        }
      } else {
        this.stepsConfirmation[this.currentStep] = false;
      }
    }
  },
  computed: {
    isNextStepAvailable() {
      return this.stepsConfirmation[this.currentStep];
    },
    isIntegrationEnabled() {
      for (let i = 1; i <= this.stepsAmount; i++) {
        if (!this.stepsConfirmation[i]) {
          return false;
        }
      }

      return !this.isInProcess;
    },
    currentStepComponent() {
      return 'step-' + this.currentStep;
    },
  },
}
</script>

<style src="./../../../styles.scss" lang="scss"></style>
<style src="./../../styles.scss" lang="scss"></style>
