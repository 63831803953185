<template>
  <div class="integration-step">
    <div class="step-description">
      <div class="sub-step">
        <div class="sub-step-title">
          Step 2:
        </div>
        <ol>
          <li>Click the <b>"+ Add"</b> button</li>
          <li>Enter name <b>“Asolytics”</b></li>
          <li>Select access <b>“App Manager”</b> and <b>“Sales and Report”</b></li>
          <li>Click <b>“Generate”</b></li>
        </ol>
      </div>
    </div>
    <div class="img-wrap">
      <img src="@/assets/images/integrations/app-store/api-2.png" width="540">
    </div>
  </div>
</template>
<script>

export default {
  name: 'AppStoreConnectViaAPIStep2',
  created() {
    this.$emit('confirmation-changed', true);
  },
}
</script>
