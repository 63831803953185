<template>
  <div class="integration-step">
    <div class="step-description">
      <div class="step-1-disclaimer">
        ☝️ Make sure you have an <b>Owner</b> or <b>Admin</b> account in App Store Connect
      </div>

      <div class="sub-step">
        <div class="sub-step-title">
          Step 1:
        </div>
        <ol>
          <li>Navigate to the <b>Users and Access</b> section</li>
          <li>Switch to the <b>Integrations</b> tab</li>
          <li>Go to the <b>Team Keys</b> subsection</li>
        </ol>
      </div>
    </div>
    <div class="img-wrap">
      <img src="@/assets/images/integrations/app-store/api-1.png" width="540">
    </div>
  </div>
</template>
<script>
export default {
  name: 'AppStoreConnectViaAPIStep1',
  created() {
    this.$emit('confirmation-changed', true);
  },
}
</script>
<style scoped>
.step-1-disclaimer {
  background: var(--neutral-100);
  border-radius: 8px;
  padding: 16px;
  margin-bottom: 16px;
}
</style>
