<template>
  <div class="integration-step">
    <div class="step-description step-3">
      <div class="integration-error-block" v-if="error">
        Something went wrong while trying to access your App Store Console. Please check the guide to fix it.
      </div>

      <div class="sub-step">
        <div class="sub-step-title">
          Step 3:
        </div>
        <ol>
          <li>
            Copy the <b>Issuer ID</b> and paste it to the fields below
            <div class="step-3-input-wrap">
              <div>Issuer ID</div>
              <input v-model="issuer_id" class="issuer-id" type="text" placeholder="XXXX-XXXX-XXXX-XXXXXX" />
            </div>
          </li>
          <li>
            Copy the <b>Key ID</b> and paste it to the fields below
            <div class="step-3-input-wrap">
              <div>Key ID</div>
              <input v-model="key_id" class="key-id" type="text" placeholder="XXXXXXXXX" />
            </div>
          </li>
          <li>
            Click <b>Download API Key</b> and upload this file below
            <div class="step-3-input-wrap">
              <div>API Key</div>
              <base-button v-if="api_key === null && saved_file_name === null"
                           height="36px"
                           width="145px"
                           classes="file-button"
                           @click="chooseFile"
                           font-size="14px"
                           mode="outline"
                           border-radius="4px">
                <svg-icon icon="paperclip" />
                Upload file
                <input id="fileUpload"
                       ref="file"
                       type="file"
                       @change="handleFileUpload"
                       hidden>
              </base-button>
              <div v-else class="selected-file-wrap">
                <div class="selected-file">
                  {{ api_key?.name || saved_file_name }}
                </div>
                <table-actions-tooltip class="clear-file-button"
                                       @click="clearFile">
                  <template v-slot:tooltip-icon>
                    <svg-icon icon="close-alt" />
                  </template>
                  <template v-slot:tooltip-text>
                    Clear
                  </template>
                </table-actions-tooltip>
              </div>
            </div>
            <div v-if="fileError" class="file-error-block">
              {{ fileError }}
            </div>
          </li>
        </ol>
      </div>
    </div>
    <div class="img-wrap">
      <img src="@/assets/images/integrations/app-store/api-3.png" width="540">
    </div>
  </div>
</template>
<script>
import SvgIcon from "@/components/UI/SvgIcon/index.vue";
import TableActionsTooltip from "@/components/DataTables/Tools/TableActionsTooltip/index.vue";

export default {
  name: 'AppStoreConnectViaAPIStep3',
  components: {
    TableActionsTooltip,
    SvgIcon,
  },
  props: {
    data: {
      type: Object,
      default: {},
    },
    error: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      fileError: null,
      api_key: this.data.api_key || null,
      saved_file_name: this.data.saved_file_name || null,
      issuer_id: this.data.issuer_id || null,
      key_id: this.data.key_id || null,
    };
  },
  methods: {
    clearFile() {
      this.api_key = null;
      this.saved_file_name = null;
    },
    chooseFile() {
      document.getElementById("fileUpload").click()
    },
    async handleFileUpload() {
      this.fileError = null;
      this.api_key = null;

      const file = this.$refs.file.files[0];
      const fileSize = file.size;
      const fileType = file.name.split('.').pop();

      if (fileType !== 'p8') {
        this.fileError = '⚠️ Wrong file type selected. Only .p8 is allowed';
        return;
      }
      if (fileSize > 10240) {
        this.fileError = '⚠️ Wrong file size. Max size 10KB allowed.';
        return;
      }

      this.api_key = file;
    },
  },
  computed: {
    formData() {
      if (!this.api_key || !this.issuer_id || !this.key_id) {
        return null;
      }

      return {
        api_key: this.api_key,
        issuer_id: this.issuer_id,
        saved_file_name: this.saved_file_name,
        key_id: this.key_id,
      };
    }
  },
  watch: {
    formData(oldVal, newVal) {
      this.$emit('confirmation-changed', this.formData);
    },
  },
}
</script>
<style lang="scss" scoped>
.step-3-input-wrap {
  display: flex;
  gap: 16px;
  font-size: 14px;
  align-items: center;
  margin-top: 12px;
  margin-bottom: 12px;

  input {
    height: 44px;
    padding: 10px 16px;
    border: 1px solid var(--neutral-500);
    border-radius: 4px;
    font-size: 16px;

    &.issuer-id {
      width: 220px;
    }

    &.key-id {
      width: 130px;
    }
  }
}

.file-button {
  font-weight: 600;

  .svg-icons {
    font-size: 20px;
    margin-right: 8px;
    color: white;
  }
}

.selected-file-wrap {
  display: flex;
  column-gap: 16px;
  color: var(--neutral-750);
  font-weight: 600;

  .selected-file {
    background: var(--neutral-100);
    border-radius: 4px;
    padding: 8px 12px;
  }

  .svg-icons {
    cursor: pointer;
  }
}

.file-error-block {
  font-size: 14px;
  color: var(--secondary-red);
}
</style>
